import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import { UserProvider } from './contexts/UserContext';
import "./i18n";
import * as serviceWorker from './serviceWorker';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter basename="elections/kenya/">
    <UserProvider>
      <App />
    </UserProvider>
  </BrowserRouter>
);

serviceWorker.unregister();