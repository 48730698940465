import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Spinner from './shared/Spinner';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));

const Buttons = lazy(() => import('./basic-ui/Buttons'));
const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
const Typography = lazy(() => import('./basic-ui/Typography'));

const BasicElements = lazy(() => import('./form-elements/BasicElements'));

const BasicTable = lazy(() => import('./tables/BasicTable'));

const Mdi = lazy(() => import('./icons/Mdi'));

const ChartJs = lazy(() => import('./charts/ChartJs'));

const Error404 = lazy(() => import('./errors/Error404'));
const Error500 = lazy(() => import('./errors/Error500'));

const Login = lazy(() => import('./user/Login'));
const Register = lazy(() => import('./user/Register'));
const Infosec = lazy(() => import('./infosec/Infosec'));

const Presidential = lazy(() => import('./presidential/Presidential'));
const Parliamentary = lazy(() => import('./parliamentary/Parliamentary'));
const Senatorial = lazy(() => import('./senatorial/Senatorial'));
const Gubernatorial = lazy(() => import('./gubernatorial/Gubernatorial'));
const Mca = lazy(() => import('./mca/Mca'));
const WomenRep = lazy(() => import('./womenrep/WomenRep'));

 
class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/register" component={Register} />
          <Route path="/infosec" component={Infosec} />

          <Route path="/presidential" component={Presidential} />
          <Route path="/parliamentary" component={Parliamentary} />
          <Route path="/senatorial" component={Senatorial} />
          <Route path="/gubernatorial" component={Gubernatorial} />
          <Route path="/mca" component={Mca} />
          <Route path="/womenrep" component={WomenRep} />

          <Route path="/error/error-404" component={Error404} />
          <Route path="/error/error-500" component={Error500} />

          <Route path="/basic-ui/buttons" component={Buttons} />
          <Route path="/basic-ui/dropdowns" component={Dropdowns} />
          <Route path="/basic-ui/typography" component={Typography} />

          <Route path="/form-Elements/basic-elements" component={BasicElements} />

          <Route path="/tables/basic-table" component={BasicTable} />

          <Route path="/icons/mdi" component={Mdi} />

          <Route path="/charts/chart-js" component={ChartJs} />

          <Redirect to="/login" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;